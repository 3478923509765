<template lang="pug">
  v-container.col-md-12.col-12
    v-dialog(v-if="dialogPatient" fullscreen="" v-model='dialogPatient' width='800')
      v-card
        patient(:seeData="false" @finishCreate="finishCreate" :selectedPatient="selectedPatient" :idPatient="idPatient" @exitPatient="dialogPatient = false")
    v-dialog(v-model='confirmationDialog' persistent='' max-width='290')
      v-card
        v-card-title.headline
          | Confirmar
        v-card-text &iquest;Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialog = false')
              | Cancelar
            v-btn(color='blue darken-1' @click='acceptConfirmation')
              | Aceptar
    v-row
      v-col(cols="12" md="5")
        base-material-card.px-5.py-3(icon='mdi-clipboard-text' title='Lista de pacientes')
          v-container#patient-table(fluid='' tag='section')
            v-row
              v-col(cols="1" md="1"  style="right: 50px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='secondary' @click="downLoadExcelFile")
                      v-icon(style="font-size: 30px;") mdi-arrow-down-bold-box
                  span Descargar Lista de Exámenes Físicos
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 90px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='primary' @click="newPatient")
                      v-icon(style="font-size: 30px;") mdi-account-plus
                  span Agregar Nuevo Paciente
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 130px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='orange' @click="viewPatient")
                      v-icon(style="font-size: 30px;") mdi-eye
                  span Ver datos del paciente
              v-col(cols="12" md="12" style="padding: 0px; margin: 0px;" v-if="patientSelected == null" )
                v-autocomplete(@change="changePatient" v-model='valuePatient' :items='patientLstFilter' return-object item-text="data.data.completeData"  label='Pacientes')
                  template(v-slot:no-data='')
                    v-list-item
                      v-list-item-title
                        | Aún no hay pacientes, regístralos desde el menú
                        strong  Pacientes
              v-col(cols="12" md="12")
                v-progress-linear(indeterminate='' color='primary' v-if="progressBar")
                v-simple-table
                  thead
                    tr
                      th.primary--text
                        | Paciente
                      th.primary--text
                        | Fecha
                      th.text-center.primary--text
                        | Eliminar
                  tbody
                    tr(v-for="(examenFisico, index) in arrExamenFisicoLst" :key="index")
                      td(@click="selectExamenFisico(examenFisico)" style="cursor: pointer;") {{examenFisico.data.data.patientCompletData.toString().split(' | ')[0]}}
                      td(@click="selectExamenFisico(examenFisico)" style="cursor: pointer;") {{examenFisico.data.data.currentDate}}
                      td(style="cursor: pointer;").text-center
                        v-btn(icon='' color='red' @click="deleteExamenFisico(examenFisico)")
                          v-icon mdi-delete
        v-row
          v-col(cols="6" md="6").text-center
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='secondary' @click="cleanData")
                  v-icon(style="font-size: 40px;") mdi-clipboard-plus
              span Nuevo Registro
          v-col(cols="6" md="6")
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='primary' @click="saveHistory" :loading="loadingSaveData")
                  v-icon(style="font-size: 40px;") mdi-content-save
              span Guardar Datos
      v-col(cols="12" md="7")
        v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading
          v-row.text-start.v-card--material__heading.mb-n6.v-sheet.theme--dark.elevation-6.primary.pa-7(justify='center' no-gutters='')
            | Examen Físico
          v-checkbox(@change="changeSinPatologia" input-label='true' v-model='sinpatologia' :label='`Exámen sin patología`')
          v-row
            v-col(cols="12" md="6" disabled='sinpatologia')
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Art. Témporo-Mandibular
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPtemporoMandibular" v-model='examenFisico.temporoMandibular' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.temporoMandibular == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.temporoMandibularData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.temporoMandibularData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.temporoMandibularData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.temporoMandibularData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.temporoMandibularData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.temporoMandibularData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Mejillas
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPmejillas" v-model='examenFisico.mejillas' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.mejillas == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.mejillasData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.mejillasData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.mejillasData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.mejillasData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.mejillasData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.mejillasData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Cigarrillos
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPcigarrillos" v-model='examenFisico.cigarrillos' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.cigarrillos == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.cigarrillosData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.cigarrillosData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.cigarrillosData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.cigarrillosData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.cigarrillosData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.cigarrillosData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Maxilar Inferior
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPmaxilarInferior" v-model='examenFisico.maxilarInferior' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.maxilarInferior == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.maxilarInferiorData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.maxilarInferiorData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.maxilarInferiorData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.maxilarInferiorData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.maxilarInferiorData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.maxilarInferiorData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Glándulas Salivales
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPglandulasSalivales" v-model='examenFisico.glandulasSalivales' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.glandulasSalivales == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.glandulasSalivalesData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.glandulasSalivalesData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.glandulasSalivalesData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.glandulasSalivalesData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.glandulasSalivalesData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.glandulasSalivalesData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Maxilar Superior
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPmaxilarSuperior" v-model='examenFisico.maxilarSuperior' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.maxilarSuperior == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.maxilarSuperiorData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.maxilarSuperiorData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.maxilarSuperiorData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.maxilarSuperiorData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.maxilarSuperiorData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.maxilarSuperiorData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Ganglios Cabeza Cuello
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPgangliosCabezaCuello" v-model='examenFisico.gangliosCabezaCuello' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.gangliosCabezaCuello == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.gangliosCabezaCuelloData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.gangliosCabezaCuelloData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.gangliosCabezaCuelloData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.gangliosCabezaCuelloData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.gangliosCabezaCuelloData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.gangliosCabezaCuelloData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Paladar
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPpaladar" v-model='examenFisico.paladar' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.paladar == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.paladarData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.paladarData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.paladarData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.paladarData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.paladarData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.paladarData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Lengua
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPlengua" v-model='examenFisico.lengua' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.lengua == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.lenguaData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.lenguaData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.lenguaData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.lenguaData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.lenguaData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.lenguaData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Piso de Boca
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPpisoBoca" v-model='examenFisico.pisoBoca' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.pisoBoca == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.pisoBocaData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.pisoBocaData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.pisoBocaData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.pisoBocaData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.pisoBocaData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.pisoBocaData.obs")
            v-col(cols="12" md="6")
              v-row
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  p(style="color: gray;") Labios
                v-col(cols='12' md='6' style="padding: 0px; padding-left: 10px; justify-content:center; display:flex;")
                  v-radio-group(style="margin-top: 0px;" @change="activeCPlabios" v-model='examenFisico.labios' :disabled='sinpatologia' row='')
                    v-radio(label='CP' value='CP')
                    v-radio(label='SP' value='SP')
                v-col(cols="12" md="12" v-if="examenFisico.labios == 'CP'" style="padding: 0px 10px;")
                  v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading(style="margin: 0px; padding: 0px 10px !important;")
                    v-row
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Abseso' v-model="examenFisico.labiosData.abseso")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Fibroma' v-model="examenFisico.labiosData.fibrona")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Herpes' v-model="examenFisico.labiosData.herpes")
                      v-col(cols='4' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Úlcera' v-model="examenFisico.labiosData.ulcera")
                      v-col(cols='6' style="padding: 0px 10px 0px 10px;")
                        v-checkbox(label='Otra Patología' v-model="examenFisico.labiosData.otra")
                    v-textarea(dense height="85px" style="padding-top: 0px; margin-top: 0px;" label='Observación' v-model="examenFisico.labiosData.obs")
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/database'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      Patient: () => import('@/views/dashboard/component/patient/PatientProfile'),
    },
    props: {
      patientSelected: null,
      idPatient: null,
    },
    data () {
      return {
        loadingSaveData: false,
        selectedPatient: {},
        confirmationDialog: false,
        examenFisicoSelect: {},
        progressBar: false,
        arrExamenFisicoLst: [],
        arrExamenFisicoLstComplete: [],
        dialogPatient: false,
        valuePatient: null,
        patientID: '',
        examenFisicoID: '',
        dialog: false,
        sinpatologia: false,
        examenFisico: {
          currentDate: '',
          temporoMandibular: 'SP',
          temporoMandibularData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          mejillas: 'SP',
          mejillasData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          cigarrillos: 'SP',
          cigarrillosData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          maxilarInferior: 'SP',
          maxilarInferiorData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          glandulasSalivales: 'SP',
          glandulasSalivalesData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          maxilarSuperior: 'SP',
          maxilarSuperiorData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          gangliosCabezaCuello: 'SP',
          gangliosCabezaCuelloData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          paladar: 'SP',
          paladarData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          lengua: 'SP',
          lenguaData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          pisoBoca: 'SP',
          pisoBocaData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
          labios: 'SP',
          labiosData: {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          },
        },
        patientLstFilter: [],
      }
    },
    computed: {
      patientLst () {
        return this.$store.state.patients.pacientes
      },
    },
    watch: {
      patientLst () {
        this.patientLstFilter = this.patientLst
        if (this.patientSelected != null) {
          this.valuePatient = {
            data: {
              data: this.patientSelected,
            },
            id: this.idPatient,
          }
        } else {
          this.valuePatient = this.patientLstFilter[0]
        }
        this.getDataExamenFisico()
      },
    },
    created () {
      // if (this.patientLst === null) {
      //   this.newPatient()
      // }
      this.$store.dispatch('getCurrentDay').then(res => {
        this.examenFisico.currentDate = res
      })
      this.patientLstFilter = this.patientLst
      if (this.patientSelected != null) {
        this.valuePatient = {
          data: {
            data: this.patientSelected,
          },
          id: this.idPatient,
        }
      } else {
        this.valuePatient = this.patientLstFilter[0]
      }
      this.getDataExamenFisico()
    },
    methods: {
      travelObjects (object) {
        let concatenatedString = ''
        // eslint-disable-next-line no-unused-vars
        for (const prop in object) {
          if (object[prop] === true) {
            if (concatenatedString === '') {
              concatenatedString = concatenatedString.concat(prop)
            } else {
              concatenatedString = concatenatedString + ', ' + prop
            }
          }
        }
        return concatenatedString
      },
      downLoadExcelFile () {
        let cigarrillosDataString = ''
        let gangliosCabezaCuelloDataString = ''
        let glandulasSalivalesDataString = ''
        let labiosDataString = ''
        let lenguaDataString = ''
        let maxilarInferiorDataString = ''
        let maxilarSuperiorDataString = ''
        let mejillasDataString = ''
        let paladarDataString = ''
        let pisoBocaDataString = ''
        let temporoMandibularDataString = ''
        const data = []
        if (this.arrExamenFisicoLstComplete) {
          for (let i = 0; i < this.arrExamenFisicoLstComplete.length; i++) {
            cigarrillosDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.cigarrillosData)
            gangliosCabezaCuelloDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.gangliosCabezaCuelloData)
            glandulasSalivalesDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.glandulasSalivalesData)
            labiosDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.labiosData)
            lenguaDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.lenguaData)
            maxilarInferiorDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.maxilarInferiorData)
            maxilarSuperiorDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.maxilarSuperiorData)
            mejillasDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.mejillasData)
            paladarDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.paladarData)
            pisoBocaDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.pisoBocaData)
            temporoMandibularDataString = this.travelObjects(this.arrExamenFisicoLstComplete[i].data.data.temporoMandibularData)
            data.push({
              Cigarrillos: this.arrExamenFisicoLstComplete[i].data.data.cigarrillos,
              'Cigarrillos datos': cigarrillosDataString,
              Fecha: this.arrExamenFisicoLstComplete[i].data.data.currentDate,
              'Ganglios cabeza-cuello': this.arrExamenFisicoLstComplete[i].data.data.gangliosCabezaCuello,
              'Ganglios cabeza-cuello datos': gangliosCabezaCuelloDataString,
              'Glandulas salivales': this.arrExamenFisicoLstComplete[i].data.data.glandulasSalivales,
              'Glandulas salivales datos': glandulasSalivalesDataString,
              Labios: this.arrExamenFisicoLstComplete[i].data.data.labios,
              'Labios datos': labiosDataString,
              Lengua: this.arrExamenFisicoLstComplete[i].data.data.lengua,
              'Lengua datos': lenguaDataString,
              'Maxilar inferior': this.arrExamenFisicoLstComplete[i].data.data.maxilarInferior,
              'Maxilar inferior datos': maxilarInferiorDataString,
              'Maxilar superior': this.arrExamenFisicoLstComplete[i].data.data.maxilarSuperior,
              'Maxilar superior datos': maxilarSuperiorDataString,
              Mejillas: this.arrExamenFisicoLstComplete[i].data.data.mejillas,
              'Mejillas datos': mejillasDataString,
              Paladar: this.arrExamenFisicoLstComplete[i].data.data.paladar,
              'Paladar datos': paladarDataString,
              'Datos del paciente': this.arrExamenFisicoLstComplete[i].data.data.patientCompletData,
              'Piso boca': this.arrExamenFisicoLstComplete[i].data.data.pisoBoca,
              'Piso boca datos': pisoBocaDataString,
              'Temporo mandibular': this.arrExamenFisicoLstComplete[i].data.data.temporoMandibular,
              'Temporo mandibular datos': temporoMandibularDataString,
            })
          }
          functions.JSONToCSVConvertor(data, 'Examen_Fisico', true)
        }
      },
      newPatient () {
        this.selectedPatient = null
        this.dialogPatient = true
      },
      viewPatient () {
        if (this.valuePatient) {
          this.idPatient = this.valuePatient.id
          this.selectedPatient = this.valuePatient.data.data
          this.dialogPatient = true
        }
      },
      getDataExamenFisico () {
        this.progressBar = true
        this.arrExamenFisicoLst = []
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/examen-fisico/${currentUser.uid}/`).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
              })
              for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr[i].data.length; j++) {
                  arr[i].data[j].idPatient = arr[i].id
                  this.arrExamenFisicoLst.push(arr[i].data[j])
                }
              }
              this.arrExamenFisicoLstComplete = this.arrExamenFisicoLst
              this.changePatient()
            }
            this.progressBar = false
          }
        })
      },
      cleanData () {
        this.sinpatologia = true
        this.changeSinPatologia()
        this.patientID = ''
        this.examenFisicoID = ''
      },
      changeSinPatologia () {
        if (this.sinpatologia) {
          this.examenFisico.temporoMandibular = 'SP'
          this.examenFisico.temporoMandibularData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.mejillas = 'SP'
          this.examenFisico.mejillasData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.cigarrillos = 'SP'
          this.examenFisico.cigarrillosData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.maxilarInferior = 'SP'
          this.examenFisico.maxilarInferiorData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.glandulasSalivales = 'SP'
          this.examenFisico.glandulasSalivalesData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.maxilarSuperior = 'SP'
          this.examenFisico.maxilarSuperiorData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.gangliosCabezaCuello = 'SP'
          this.examenFisico.gangliosCabezaCuelloData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.paladar = 'SP'
          this.examenFisico.paladarData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.lengua = 'SP'
          this.examenFisico.lenguaData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.pisoBoca = 'SP'
          this.examenFisico.pisoBocaData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
          this.examenFisico.labios = 'SP'
          this.examenFisico.labiosData = {
            abseso: false,
            fibrona: false,
            herpes: false,
            ulcera: false,
            otra: false,
            obs: '',
          }
        }
      },
      activeCPtemporoMandibular () {
        this.sinpatologia = false
        this.examenFisico.temporoMandibularData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPmejillas () {
        this.sinpatologia = false
        this.examenFisico.mejillasData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPcigarrillos () {
        this.sinpatologia = false
        this.examenFisico.cigarrillosData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPmaxilarInferior () {
        this.sinpatologia = false
        this.examenFisico.maxilarInferiorData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPmaxilarSuperior () {
        this.sinpatologia = false
        this.examenFisico.maxilarSuperiorData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPglandulasSalivales () {
        this.sinpatologia = false
        this.examenFisico.glandulasSalivalesData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPgangliosCabezaCuello () {
        this.sinpatologia = false
        this.examenFisico.gangliosCabezaCuelloData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPpaladar () {
        this.sinpatologia = false
        this.examenFisico.paladarData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPlengua () {
        this.sinpatologia = false
        this.examenFisico.lenguaData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPpisoBoca () {
        this.sinpatologia = false
        this.examenFisico.pisoBocaData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      activeCPlabios () {
        this.sinpatologia = false
        this.examenFisico.labiosData = {
          abseso: false,
          fibrona: false,
          herpes: false,
          ulcera: false,
          otra: false,
          obs: '',
        }
      },
      selectExamenFisico (examenFisico) {
        this.examenFisico = examenFisico.data.data
        this.patientID = examenFisico.idPatient
        this.examenFisicoID = examenFisico.id
      },
      changePatient () {
        this.arrExamenFisicoLst = this.arrExamenFisicoLstComplete.filter(item => {
          return (item.data.data.patientCompletData === this.valuePatient.data.data.completeData)
        })
      },
      finishCreate () {
        this.dialogPatient = false
      },
      deleteExamenFisico (examenFisico) {
        this.confirmationDialog = true
        this.examenFisicoSelect = examenFisico
      },
      acceptConfirmation () {
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/examen-fisico/${currentUser.uid}/${this.examenFisicoSelect.idPatient}/${this.examenFisicoSelect.id}/`).remove().then(() => {
          this.getDataExamenFisico()
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Examen Físico eliminado correctamente',
          }
          this.cleanData()
          this.confirmationDialog = false
        })
      },
      saveHistory () {
        this.loadingSaveData = true
        const currentUser = firebase.auth().currentUser
        this.$store.dispatch('getCurrentDay').then(res => {
          this.examenFisico.currentDate = res
          if (this.patientID === '' && this.examenFisicoID === '') {
            if (this.valuePatient) {
              this.examenFisico.patientCompletData = this.valuePatient.data.data.completeData
              const user = this.$store.state.users.user
              firebase.database().ref(`/${user.selectAccount}/examen-fisico/${currentUser.uid}/${this.valuePatient.id}/`).push(
                {
                  data: this.examenFisico,
                }).then((res) => {
                this.getDataExamenFisico()
                this.loadingSaveData = false
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Examen Físico guardado correctamente',
                }
                this.cleanData()
              }).catch(() => {
                this.loadingSaveData = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al guardar el Examen Fisico',
                }
              })
            } else {
              this.loadingSaveData = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Debe seleccionar el paciente para continuar',
              }
            }
          } else {
            const user = this.$store.state.users.user
            firebase.database().ref(`/${user.selectAccount}/examen-fisico/${currentUser.uid}/${this.patientID}/${this.examenFisicoID}/data/`).set(
              this.examenFisico).then((res) => {
              this.getDataExamenFisico()
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Examen Físico guardado correctamente',
              }
              this.loadingSaveData = false
              this.cleanData()
            })
          }
        })
      },
    },
  }
</script>
<style lang='css'>
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
    font-family: 'ceraProMedium';
  }
</style>
